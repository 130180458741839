var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"fill-height"},[_c('v-col',[_c('v-sheet',{attrs:{"height":"64"}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-btn',{staticClass:"mr-4",attrs:{"outlined":"","color":"grey darken-2"},on:{"click":_vm.setToday}},[_vm._v(" Today ")]),_c('v-btn',{attrs:{"fab":"","text":"","small":"","color":"grey darken-2"},on:{"click":_vm.prev}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-chevron-left ")])],1),_c('v-btn',{attrs:{"fab":"","text":"","small":"","color":"grey darken-2"},on:{"click":_vm.next}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-chevron-right ")])],1),(_vm.$refs.calendar)?_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$refs.calendar.title)+" ")]):_vm._e(),_c('div',{staticStyle:{"width":"15rem"}},[_c('v-select',{attrs:{"items":_vm.users,"item-text":function (item) { return ((item.firstname) + " " + (item.lastname)); },"label":"Select User","outlined":"","dense":"","hide-details":""},model:{value:(_vm.selectedUser),callback:function ($$v) {_vm.selectedUser=$$v},expression:"selectedUser"}})],1),_c('v-spacer'),_c('v-menu',{attrs:{"bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":"","color":"grey darken-2"}},'v-btn',attrs,false),on),[_c('span',[_vm._v(_vm._s(_vm.typeToLabel[_vm.type]))]),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-menu-down ")])],1)]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){_vm.type = 'day'}}},[_c('v-list-item-title',[_vm._v("Day")])],1),_c('v-list-item',{on:{"click":function($event){_vm.type = 'week'}}},[_c('v-list-item-title',[_vm._v("Week")])],1),_c('v-list-item',{on:{"click":function($event){_vm.type = 'month'}}},[_c('v-list-item-title',[_vm._v("Month")])],1),_c('v-list-item',{on:{"click":function($event){_vm.type = '4day'}}},[_c('v-list-item-title',[_vm._v("4 days")])],1)],1)],1)],1)],1),_c('v-sheet',{attrs:{"height":"600"}},[_vm._l((_vm.selectedUsersCalendars),function(user){return _c('v-calendar',{key:user.id,ref:"calendar",refInFor:true,attrs:{"color":"primary","events":user.events,"event-color":_vm.getEventColor,"type":_vm.type,"value":_vm.focus},on:{"click:event":_vm.showEvent,"click:more":_vm.viewDay,"click:date":_vm.viewDay,"input":_vm.updateFocus}})}),_c('v-menu',{attrs:{"close-on-content-click":false,"activator":_vm.selectedElement,"offset-y":"","top":"","min-width":"290px"},model:{value:(_vm.selectedOpen),callback:function ($$v) {_vm.selectedOpen=$$v},expression:"selectedOpen"}},[_c('v-card',{attrs:{"color":"grey lighten-4","flat":""}},[_c('v-toolbar',{attrs:{"height":"44","color":_vm.selectedEvent.color,"dark":""}},[(_vm.rightsDev)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.openModalUpdate(_vm.selectedEvent)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e(),_c('v-toolbar-title',{domProps:{"innerHTML":_vm._s(_vm.selectedEvent.name)}}),_c('v-spacer'),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-card',[_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary"}},[_vm._v("mdi-state-machine")]),_c('v-list-item-title',{staticClass:"purple--text"},[_vm._v("Changer le statut")])],1)],1)]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.statusChange('prise_en_compte')}}},[_c('v-list-item-title',[_vm._v("Prise en compte")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.statusChange('en_cours')}}},[_c('v-list-item-title',[_vm._v("En cours")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.statusChange('terminé')}}},[_c('v-list-item-title',[_vm._v("Terminé")])],1)],1)],1)],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Début souhaité","value":_vm._f("formatDate")(_vm.selectedEvent.start),"readonly":""}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Fin souhaitée","value":_vm._f("formatDate")(_vm.selectedEvent.end),"readonly":""}})],1),_c('v-divider'),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Details","value":_vm._f("escapeHTML")(_vm.selectedEvent.details),"readonly":""}})],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"secondary"},on:{"click":function($event){_vm.selectedOpen = false}}},[_vm._v(" Fermer ")])],1)],1)],1)],2)],1),_c('v-dialog',{attrs:{"max-width":"50%"},model:{value:(_vm.editModal),callback:function ($$v) {_vm.editModal=$$v},expression:"editModal"}},[_c('EditDev',{on:{"close-edit":function($event){_vm.editModal = false}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }