<template>
  <v-row class="fill-height">
    <v-col>
      <v-sheet height="64">
        <v-toolbar
          flat
        >
          <v-btn
            outlined
            class="mr-4"
            color="grey darken-2"
            @click="setToday"
          >
            Today
          </v-btn>
          <v-btn
            fab
            text
            small
            color="grey darken-2"
            @click="prev"
          >
            <v-icon small>
              mdi-chevron-left
            </v-icon>
          </v-btn>
          <v-btn
            fab
            text
            small
            color="grey darken-2"
            @click="next"
          >
            <v-icon small>
              mdi-chevron-right
            </v-icon>
          </v-btn>
          <v-toolbar-title v-if="$refs.calendar">
            {{ $refs.calendar.title }}
          </v-toolbar-title>
          <div style="width: 15rem;">
            <v-select 
              v-model="selectedUser"
              :items="users"
              :item-text="item => `${item.firstname} ${item.lastname}`"
              label="Select User"
              outlined
              dense
              hide-details
            ></v-select>
          </div>
          
          <v-spacer></v-spacer> 
          <v-menu
            bottom
            right
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                outlined
                color="grey darken-2"
                v-bind="attrs"
                v-on="on"
              >
                <span>{{ typeToLabel[type] }}</span>
                <v-icon right>
                  mdi-menu-down
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="type = 'day'">
                <v-list-item-title>Day</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'week'">
                <v-list-item-title>Week</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'month'">
                <v-list-item-title>Month</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = '4day'">
                <v-list-item-title>4 days</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
      </v-sheet>
      <v-sheet height="600">
        <!-- <pre v-for="user in selectedUsersCalendars" :key="user.id">{{ user.id }}</pre> -->
        <v-calendar
          v-for="user in selectedUsersCalendars"
          :key="user.id"
          ref="calendar"
          color="primary"
          :events="user.events"
          :event-color="getEventColor"
          :type="type"
          @click:event="showEvent"
          @click:more="viewDay"
          @click:date="viewDay"
          @input="updateFocus"
          :value="focus"
        ></v-calendar>
        <v-menu
          v-model="selectedOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
          offset-y top
          min-width="290px"
        >
          <v-card
            color="grey lighten-4"
            flat
          >
            <v-toolbar
              height="44"
              :color="selectedEvent.color"
              dark
            >
              <v-btn
                icon
                @click="openModalUpdate(selectedEvent)"
                v-if="rightsDev"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
              <v-spacer></v-spacer> 
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-card>
                    <v-list-item 
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon color="primary" class="mr-2">mdi-state-machine</v-icon>  
                      <v-list-item-title
                        class="purple--text"
                      >Changer le statut</v-list-item-title>
                    </v-list-item>
                  </v-card>
                </template>
                  <v-list>
                    <v-list-item @click="statusChange('prise_en_compte')">
                      <v-list-item-title>Prise en compte</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="statusChange('en_cours')">
                      <v-list-item-title>En cours</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="statusChange('terminé')">
                      <v-list-item-title>Terminé</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-menu>
            </v-toolbar>
            <v-card-text> 
              <!-- l'heure -->
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    label="Début souhaité"
                    :value="selectedEvent.start | formatDate"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="Fin souhaitée"
                    :value="selectedEvent.end | formatDate"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-divider></v-divider>
                <!-- details en readOnly -->
                <v-col cols="12">
                  <v-text-field
                    label="Details"
                    :value="selectedEvent.details | escapeHTML"
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn
                text
                color="secondary"
                @click="selectedOpen = false"
              >
                Fermer
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-sheet>
    </v-col>
    <!-- modale d edition -->
    <v-dialog v-model="editModal" max-width="50%">
      <EditDev @close-edit="editModal = false"/>
    </v-dialog>
  </v-row>
   
</template>

<script>
import UserService from '@/Services/UserService';
import DeveloppementService from '@/Services/SupportVision/DeveloppementService';
import * as Vuex from "vuex";
import moment from 'moment'

export default {
  components: {
    EditDev: () => import("@/Components/Views/SupportVision/Cartes/editDev"),
  },
  data: () => ({
    users: [],
    editModal: false,
    selectedUser: null,
    focus:  moment().format('YYYY-MM-DD'),
    type: 'month',
    typeToLabel: {
      month: 'Month',
      week: 'Week',
      day: 'Day',
      '4day': '4 Days',
    },
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    events: [],
  }),
  filters: {
    formatDate (date) {
      return moment(date).format('DD/MM/YYYY - HH:mm')
    },
    // echappempent des balises html
    escapeHTML(value) {
      if (value) {
        return value.replace(/<[^>]*>/g, '');
    } else {
      return '';
    }
    }
  },
  computed: {
    ...Vuex.mapState(['informations', 'taskSupportVision', 'rightsDev']),
    ...Vuex.mapMutations(['setTaskId', 'setTaskSupportVision']),
    // Obtenir les calendriers des utilisateurs sélectionnés
    selectedUsersCalendars() {
      if (!this.selectedUser) return [];
      return [{
        id: this.selectedUser.id,
        focus: '',
        events: this.events, // Utilisez les événements chargés
      }];
    },
    getColorByDemande() {
      return (demande) => {
        if (demande == 'Travaux unique') {
          return 'green';
        } else if (demande == 'Release') {
          return 'blue';
        } else if (demande == 'Urgent') {
          return 'red';
        }
      }
    },
    getColorByStatus() {
      return (status) => {
        if (status == 'prise_en_compte') {
          return 'blue';
        } else if (status == 'en_cours') {
          return 'orange';
        } else if (status == 'terminé') {
          return 'green';
        }
      }
    }
  },
  watch: {
    selectedUser: 'loadEventsForSelectedUser', // Chargez les événements pour l'utilisateur sélectionné lorsqu'il change
    // charger les evenements à chaque fois que les tâches sont modifiées depuis le store
    taskSupportVision: 'loadEventsForSelectedUser',
  },
  async mounted () {
    await this.loadUsers();
    await this.loadEventsForSelectedUser(); // Chargez les événements au démarrage
  },
  methods: {
    // Chargement des utilisateurs
    async loadUsers() {
      await UserService.getUsers().then((res) => {
        this.users = res;
      });
      this.selectedUser = this.informations.firstname + ' ' + this.informations.lastname;
    },
    // Chargement des événements pour l'utilisateur sélectionné
    async loadEventsForSelectedUser() { 
      // Filtrer les données qui ont été affectées
      const data = this.taskSupportVision.filter((item) => item.carte.assignement !== undefined);
      // Liste des devs affectés
      let userAffected = data.map((item) => item.carte.assignement !== undefined ? item.carte.assignement.assignedTo : []);
      // Vérifiez si le dev sélectionné est dans la liste des devs affectés
      if (userAffected !== undefined && userAffected.flat().includes(this.selectedUser)) {
        this.events = data.map((item) => {
          // Vérifier si l'utilisateur actuel est celui auquel la tâche a été affectée
          if (item.carte.assignement.assignedTo && item.carte.assignement.assignedTo.includes(this.selectedUser)) {
            return {
              name: item.carte.libelle,
              details: item.carte.description,
              start: item.carte.assignement.dateDebut,
              end: item.carte.assignement.dateFin,
              color: this.getColorByDemande(item.carte.impactType),
              id: item._id,
            };
          }
        }).filter(event => event !== undefined); // Filtrer les événements undefined
      } else {
        this.events = [];
      }
    },
    async statusChange(status) {
      // Mettre à jour le statut de la tâche et envoyer toute la tache et ajouter le statut
      let task = this.selectedEvent;
      
      task.status = status;
      // ajouter la date et l'heure du changement de statut 
      task.dateStatusChange = moment().format('YYYY-MM-DDTHH:mm');
      await DeveloppementService.editDev(task.id, task);
      this.loadEventsForSelectedUser();
      this.selectedOpen = false;
      const res = await DeveloppementService.getDev()
      this.$store.commit('setTaskSupportVision', res.data);
    },
    updateFocus(newFocus) {
      this.focus = newFocus; // Mettre à jour la valeur de focus
    },
    viewDay ({ date }) {
      this.focus = date
      this.type = 'day'
    },
    getEventColor (event) {
      return event.color
    },
    setToday () {
      this.focus = ''
    },
    prev () { 
      this.focus = moment(this.focus).subtract(1, 'month').format('YYYY-MM-DD');
    },
    next () {
      this.focus = moment(this.focus).add(1, 'month').format('YYYY-MM-DD');
    },
    showEvent ({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        requestAnimationFrame(() => requestAnimationFrame(() => open()))
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    }, 
    async openModalUpdate(event) {
      this.editModal = true;
      this.$store.commit('setTaskId', event.id);
    }
  },
}
</script>
